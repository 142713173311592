<template>
  <div>
    <v-dialog
      v-model="activo"
      width="800"
    >
      <v-card flat>
        <v-card-title>
          <span class="">Devolucion de Paquetes</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-0 px-4">
          <v-form ref="formLote" v-model="formLote" @submit.prevent="grabarDevolucion">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                  <v-autocomplete 
                    v-model="selectedABodega" 
                    :items="bodegasA" 
                    :rules="loteRules" 
                    item-text="bodega_nombre"
                    label="Bodega destino"
                    dense
                    hide-details
                    return-object
                    outlined
                  ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-simple-table fixed-header height="300px" class="elevation-2">
                  <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Código</th>
                      <th class="text-left">Cliente</th>
                      <th class="text-left">Documento N°</th>
                      <th class="text-left">Direccion</th>
                      <th class="text-left">Localidad</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in paquetes" :key="item.paquete_id">
                      <td>{{ item.paquete_id }}</td>
                      <td>{{ item.destinatario_nombre }}</td>
                      <td>{{ item.destinatario_dni }}</td>
                      <td>{{ item.destinatario_domicilio }}</td>
                      <td>{{ item.destinatario_localidad }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn small icon color="error" @click="eliminardet(item)" v-on="on">
                              <v-icon class="mr-2" >fas fa-times</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-4">
          <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="cerrarModal">Cancelar</v-btn>
          <v-btn @click="grabarDevolucion" color="success">Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name:'ModalGenerarDevoluciones',
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodegas: {
      type: Array,
      default: []
    },
    repartidor: {
      type: Number,
      default: 0
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalDev', value)
      }
    },
    bodegasA: {
      get(){
        return this.bodegas
      }
    },
    repartidor_id: {
      get(){
        return this.repartidor
      }
    }
  },
  data() {
    return {
      loteRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      paquetes: [],
      formLote: false,
      selectedABodega: {}
    }
  },
  methods: {
    eliminardet(item) {
      let pos = this.paquetes.indexOf(item)
      this.paquetes.splice(pos, 1)
    },
    cerrarModal(){
      this.selectedABodega = {}
      this.paquetes        = []
      this.activo          = false
    },
    async getPaquetes(){
      this.$store.state.loading = true;
      let paquetesPeticion      = await this.$store.dispatch('repartidores/getPaquetesDevolucion', {repartidor_id: this.repartidor_id})
      this.$store.state.loading = false;
      if (paquetesPeticion.resultado == 1){
        if (paquetesPeticion.paquetes.length > 0){
          this.paquetes = paquetesPeticion.paquetes
        }else{
          this.paquetes = []
          this.$store.dispatch('show_snackbar', { text: 'No se encontraron Paquetes.', color: 'info' })
        }
      }else{
        this.$store.dispatch('show_snackbar', { text: paquetesPeticion.msj, color: 'error' })
      }
    },
    grabarDevolucion(){
      if(!this.$refs.formLote.validate()){
        return
      }
      if (Object.keys(this.selectedABodega).length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'No se seleccionó una bodega destino.', color: 'info' })
      }
      if (this.paquetes.length == 0){
        return this.$store.dispatch('show_snackbar', { text: 'Sin paquetes disponibles. No se puede devolver.', color: 'info' })
      }
      this.$emit('confirmDevolver', {paquetes: this.paquetes, bodegaDestino: this.selectedABodega.bodega_codigo})
      this.cerrarModal()
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true){
        this.getPaquetes()
      }
    }
  },
}
</script>

<style>

</style>